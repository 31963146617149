<template>
  <div>
    <vx-card title="Les Tickets" class="mb-4">
      <div>
        <div class="aab-vie-content">
          <div class="pt-2 pb-4">
            <div class="pt-2 pb-2">
              <vs-collapse>
                <vs-collapse-item class="aab-vie-collapse-item">
                  <div slot="header">
                    <div class="aab-vie-collapse-filter">Filtrage</div>
                  </div>
                  <div class="p-6">
                    <vs-row vs-w="12" vs-align="center">
                      <vs-col
                        class="pr-2"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                        vs-lg="6"
                        vs-sm="6"
                        vs-xs="12"
                      >
                        <span class="pl-1 pr-1">Du</span>
                        <div
                          class="inputx w-full flex justify-around"
                          style="align-items: center"
                        >
                          <vs-input
                            v-model="startDate"
                            type="date"
                            class="
                              aab-vie-disable-input-date
                              inputx
                              w-full
                              mb-2
                              mt-2
                            "
                          />
                        </div>
                      </vs-col>
                      <vs-col
                        class="pr-2"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                        vs-lg="6"
                        vs-sm="6"
                        vs-xs="12"
                      >
                        <span class="pl-1 pr-1">au </span>
                        <div
                          class="inputx w-full flex justify-around"
                          style="align-items: center"
                        >
                          <vs-input
                            type="date"
                            v-model="endDate"
                            :min="startDate"
                            class="
                              aab-vie-disable-input-date
                              inputx
                              w-full
                              mb-2
                              mt-2
                            "
                          />
                        </div>
                      </vs-col>

                      <vs-col
                        class="pr-4 mb-2 mt-2"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                        vs-lg="6"
                        vs-sm="6"
                        vs-xs="12"
                      >
                        <div class="w-full">
                          <span class="pl-2">Etat</span>
                          <multiselect
                            v-model="projectStatus"
                            :options="statusList"
                            :close-on-select="true"
                            placeholder="--Etat--"
                            label="label"
                            track-by="label"
                            :selectLabel="global.selectLabel"
                            :selectedLabel="global.selectedLabel"
                            :deselectLabel="global.deselectLabel"
                          >
                          </multiselect>
                        </div>
                      </vs-col>

                      <vs-col
                        class="pr-4 mb-2 mt-2"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                        vs-lg="6"
                        vs-sm="6"
                        vs-xs="12"
                      >
                        <div class="w-full">
                          <span class="pl-2">Objet</span>
                          <multiselect
                            v-model="objectChoice"
                            :options="objectListField"
                            :close-on-select="true"
                            placeholder="--Etat--"
                            label="label"
                            track-by="label"
                            :selectLabel="global.selectLabel"
                            :selectedLabel="global.selectedLabel"
                            :deselectLabel="global.deselectLabel"
                          >
                          </multiselect>
                        </div>
                      </vs-col>
                    </vs-row>
                    <vs-row vs-w="12" vs-align="center" vs-justify="center">
                      <vs-col
                        class="pt-4 pb-2"
                        vs-type="flex"
                        vs-align="center"
                        vs-justify="center"
                        vs-lg="12"
                        vs-sm="12"
                        vs-xs="12"
                      >
                        <vs-button @click="resetFilter">
                          Réinitialiser
                        </vs-button>
                      </vs-col>
                    </vs-row>
                  </div>
                </vs-collapse-item>
              </vs-collapse>
            </div>
          </div>
          <div>
            <vs-prompt
              title="Exportation"
              class="export-options"
              @cancel="clearFields"
              @accept="exportToData"
              accept-text="Valider"
              cancel-text="Annuler"
              @close="clearFields"
              :active.sync="activePrompt"
            >
              <vs-input
                v-model="fileName"
                placeholder="Entrez le nom du fichier..."
                class="w-full"
              />
              <multiselect
                v-model="selectedFormat"
                :options="formats"
                class="my-4"
                placeholder="--Choisir--"
                :selectLabel="global.selectLabel"
                :selectedLabel="global.selectedLabel"
                :deselectLabel="global.deselectLabel"
              >
                <template v-slot:noOptions>
                  <span>{{ global.selectNotFoundData }}</span>
                </template>
              </multiselect>

              <div class="flex">
                <span class="mr-4">Largeur automatique de cellule:</span>
                <vs-switch v-model="cellAutoWidth"
                  >Largeur automatique de cellule</vs-switch
                >
              </div>
            </vs-prompt>

            <div
              class="
                flex flex-wrap-reverse
                items-center
                data-list-btn-container
              "
            >
                <div>
                <div>
                  <vs-dropdown class="dd-actions cursor-pointer ">
                    <div
                      class="
                        p-3
                        shadow-drop
                        rounded-lg
                        d-theme-dark-bg
                        cursor-pointer
                        flex
                        items-center
                        justify-center
                        text-lg
                        font-medium
                        w-32 w-full
                      "
                    >
                      <span class="mr-2">Afficher {{ limit }}</span>
                      <feather-icon
                        icon="ChevronDownIcon"
                        svgClasses="h-4 w-4"
                      />
                    </div>
                    <vs-dropdown-menu>
                      <vs-dropdown-item
                        :key="i"
                        v-for="(r, i) in rowsTable"
                        @click="limit = r"
                      >
                        <span class="flex items-center">
                          <span>{{ r }}</span>
                        </span>
                      </vs-dropdown-item>
                    </vs-dropdown-menu>
                  </vs-dropdown>
                </div>
              </div>
              <div>
                <vs-dropdown class="dd-actions cursor-pointer ml-4">
                  <div
                    class="
                      p-3
                      shadow-drop
                      rounded-lg
                      d-theme-dark-bg
                      cursor-pointer
                      flex
                      items-center
                      justify-center
                      text-lg
                      font-medium
                      w-32 w-full
                    "
                  >
                    <span class="mr-2">Actions</span>
                    <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                  </div>
                  <vs-dropdown-menu>
                    <vs-dropdown-item @click="activePrompt = true">
                      <span class="flex items-center">
                        <feather-icon
                          icon="PrinterIcon"
                          svgClasses="h-4 w-4"
                          class="mr-2"
                        />
                        <span>Exporter</span>
                      </span>
                    </vs-dropdown-item>
                  </vs-dropdown-menu>
                </vs-dropdown>
              </div>
          
            </div>
            <div class>
              <div class="w-auto" v-if="loading">
                <clip-loader :loading="loading" color="primary"></clip-loader>
              </div>
              <div v-else>
                <div v-if="allTickets.list.length !== 0">
                  <vs-table
                    multiple
                    @dblSelection="ticketSelectedDouble"
                    :data="allTickets.list"
                    v-model="ticketSelected"
                    search
                    stripe
                    pagination
                    :max-items="limit != 'Tout' ? limit : total"
                    noDataText="Aucun résultat trouvé"
                  >
                    <template slot="thead">
                      <vs-th>N°</vs-th>
                      <vs-th sort-key="whoCreated">Identité</vs-th>
                      <vs-th sort-key="ref">Référence</vs-th>
                      <vs-th sort-key="createdAt">Date</vs-th>
                      <vs-th sort-key="contrat">Contrat</vs-th>
                      <vs-th sort-key="object">Objet</vs-th>
                      <vs-th sort-key="state">Etat</vs-th>
                      <vs-th>Actions</vs-th>
                    </template>
                    <template slot-scope="{ data }">
                      <vs-tr
                        :data="tr"
                        :key="indextr"
                        v-for="(tr, indextr) in data"
                      >
                        <vs-td :data="data[indextr].id">{{
                          indextr + 1
                        }}</vs-td>
                        <vs-td :data="data[indextr].whoCreated">
                          {{ data[indextr].whoCreated.person.firstname }}
                          {{ data[indextr].whoCreated.person.lastname }}
                        </vs-td>
                        <vs-td :data="data[indextr].ref">{{
                          data[indextr].ref
                        }}</vs-td>
                        <vs-td :data="data[indextr].createdAt"
                          >{{ dateElement(data[indextr]).createdAtInfo }}
                        </vs-td>
                        <vs-td :data="data[indextr].contrat">{{
                          data[indextr].contrat
                        }}</vs-td>
                        <vs-td :data="data[indextr].object">{{
                          subString(data[indextr].object)
                        }}</vs-td>
                        <vs-td :data="data[indextr].status">
                          <div class="vx-row sm:flex hidden mt-4">
                            <div class="vx-col w-full flex">
                              <!-- legend -->
                              <div
                                class="
                                  flex flex-wrap
                                  sm:justify-start
                                  justify-center
                                "
                              >
                                <div class="flex items-center mr-4 mb-2">
                                  <div
                                    class="
                                      h-5
                                      w-5
                                      inline-block
                                      rounded-full
                                      mr-2
                                    "
                                    :style="{
                                      background: crrBackgroundColor(
                                        data[indextr].status
                                      ).background,
                                    }"
                                  ></div>
                                  <span>{{
                                    crrBackgroundColor(data[indextr].status)
                                      .text
                                  }}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </vs-td>
                        <vs-td :data="data[indextr].id">
                          <div class="inline-flex" @click.stop>
                            <vs-dropdown class="dd-actions cursor-pointer">
                              <div class="cursor-pointer">
                                <feather-icon
                                  icon="AlignJustifyIcon"
                                  svgClasses="w-6 h-6"
                                ></feather-icon>
                              </div>
                              <vs-dropdown-menu class="aab-vie-dropdown">
                                <div>
                                  <vs-dropdown-item
                                    v-if="checkUserRole('ticket').show"
                                  >
                                    <div
                                      class="ml-1 cursor-pointer"
                                      @click="checkViewTicket(data[indextr])"
                                    >
                                      <span class="flex items-center">
                                        <feather-icon
                                          icon="EyeIcon"
                                          class="text-primary"
                                          svgClasses="w-6 h-6"
                                        ></feather-icon>
                                        <span class="m-3">Ouvrir </span>
                                      </span>
                                    </div>
                                  </vs-dropdown-item>
                                  <vx-tooltip
                                    v-else
                                    color="primary"
                                    :title="role.notAllowTitle"
                                    :text="role.notAllowText"
                                  >
                                    <vs-dropdown-item disabled>
                                      <div class="ml-1 cursor-pointer">
                                        <span class="flex items-center">
                                          <feather-icon
                                            icon="EyeIcon"
                                            class="text-primary"
                                            svgClasses="w-6 h-6"
                                          ></feather-icon>
                                          <span class="m-3">Ouvrir </span>
                                        </span>
                                      </div>
                                    </vs-dropdown-item>
                                  </vx-tooltip>
                                </div>

                                <!--<div>
                                  <vs-dropdown-item
                                    v-if="checkUserRole('ticket').show"
                                  >
                                    <div
                                      class="ml-1 cursor-pointer"
                                      @click="downloadTicket(data[indextr])"
                                    >
                                      <span class="flex items-center">
                                        <feather-icon
                                          icon="DownloadIcon"
                                          class="text-primary"
                                          svgClasses="w-6 h-6"
                                        ></feather-icon>
                                        <span class="m-3">Télécharger </span>
                                      </span>
                                    </div>
                                  </vs-dropdown-item>
                                  <vx-tooltip
                                    v-else
                                    color="primary"
                                    :title="role.notAllowTitle"
                                    :text="role.notAllowText"
                                  >
                                    <vs-dropdown-item disabled>
                                      <div class="ml-1 cursor-pointer">
                                        <span class="flex items-center">
                                          <feather-icon
                                            icon="DownloadIcon"
                                            class="text-primary"
                                            svgClasses="w-6 h-6"
                                          ></feather-icon>
                                          <span class="m-3">Télécharger </span>
                                        </span>
                                      </div>
                                    </vs-dropdown-item>
                                  </vx-tooltip>
                                </div> -->

                                <div>
                                  <vs-dropdown-item
                                    :disabled="data[indextr].status === 'close'"
                                    v-if="checkUserRole('ticket').update"
                                  >
                                    <div
                                      class="ml-1 cursor-pointer"
                                      @click="ChangeStatuts(data[indextr])"
                                    >
                                      <span class="flex items-center">
                                        <feather-icon
                                          :icon="tooltipIconText(data[indextr])"
                                          class="text-primary"
                                          svgClasses="w-6 h-6"
                                        ></feather-icon>
                                        <span class="m-3"> Fermer </span>
                                      </span>
                                    </div>
                                  </vs-dropdown-item>
                                  <vx-tooltip
                                    v-else
                                    color="primary"
                                    :title="role.notAllowTitle"
                                    :text="role.notAllowText"
                                  >
                                    <vs-dropdown-item disabled>
                                      <div class="ml-1 cursor-pointer">
                                        <span class="flex items-center">
                                          <feather-icon
                                            :icon="
                                              tooltipIconText(data[indextr])
                                            "
                                            class="text-primary"
                                            svgClasses="w-6 h-6"
                                          ></feather-icon>
                                          <span class="m-3"> Fermer </span>
                                        </span>
                                      </div>
                                    </vs-dropdown-item>
                                  </vx-tooltip>
                                </div>
                              </vs-dropdown-menu>
                            </vs-dropdown>
                            <vx-tooltip
                              position="left"
                              color="primary"
                              :title="aboutElement(data[indextr]).title"
                              :text="aboutElement(data[indextr]).text"
                            >
                              <div class="cursor-pointer ml-6">
                                <feather-icon
                                  icon="InfoIcon"
                                  class="text-primary"
                                  svgClasses="w-6 h-6"
                                ></feather-icon>
                              </div>
                            </vx-tooltip>
                            <div
                              class="ml-2"
                              v-show="woIsAction == data[indextr].id"
                            >
                              <clip-loader
                                :loading="loadingUpdateTicket"
                                color="primary"
                                size="25px"
                              ></clip-loader>
                            </div>
                          </div>
                        </vs-td>
                      </vs-tr>
                    </template>
                  </vs-table>
                  <vs-pagination
                    class="mt-6"
                    :total="allPages"
                    v-model="currentPage"
                  ></vs-pagination>

                  <div class="vx-row sm:flex hidden mt-4">
                    <div class="vx-col w-full flex">
                      <!-- legend -->
                      <div
                        class="flex flex-wrap sm:justify-start justify-center"
                      >
                        <div
                          v-for="(status, index) in ticketStatus"
                          :key="index"
                          class="flex items-center mr-4 mb-2"
                        >
                          <div
                            class="h-3 w-3 inline-block rounded-full mr-2"
                            :style="{ background: status.color }"
                          ></div>
                          <span>{{ status.text }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <div class="flex items-center justify-center mt-8">
                    <strong>Aucun enregistrement</strong>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <vs-popup
            class="holamundo"
            title="Créer un ticket"
            :active.sync="popupAddTicketActive"
          >
            <div class="m-4">
              <div class="clearfix">
                <div>
                  <vs-row vs-w="12">
                    <vs-col
                      class="pr-4"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                      vs-lg="12"
                      vs-sm="12"
                      vs-xs="12"
                    >
                      <div class="w-full">
                        <span class="pl-2">Produit *</span>
                        <multiselect
                          v-model="form.product"
                          :options="allProduct"
                          placeholder="--Choisir--"
                          label="label"
                          track-by="label"
                          v-validate="'required'"
                          name="Produit"
                          required
                          :selectLabel="global.selectLabel"
                          :selectedLabel="global.selectedLabel"
                          :deselectLabel="global.deselectLabel"
                        >
                          <template v-slot:noOptions>
                            <span>{{ global.selectNotFoundData }}</span>
                          </template>
                        </multiselect>

                        <div class="aab-vie-error-msg">
                          {{ errors.first("Produit") }}
                        </div>
                      </div>
                    </vs-col>

                    <vs-col
                      class="pr-4 mt-2 mb-2"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                      vs-lg="12"
                      vs-sm="12"
                      vs-xs="12"
                    >
                      <div class="w-full">
                        <vs-input
                          class="inputx w-full mb-2 mt-2"
                          label="Objet *"
                          placeholder="Objet"
                          v-model="form.object"
                          v-validate="'required'"
                          data-vv-validate-on="blur"
                          name="object"
                        />
                        <div class="aab-vie-error-msg">
                          {{ errors.first("object") }}
                        </div>
                      </div>
                    </vs-col>

                    <vs-col
                      class="pr-4 mt-2 mb-2"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                      vs-lg="12"
                      vs-sm="12"
                      vs-xs="12"
                    >
                      <div class="w-full">
                        <div
                          class="flex mt-2 mb-3"
                          style="flex-direction: column"
                        >
                          <div class="w-full">
                            <vs-textarea
                              v-model="form.description"
                              label="Description * : "
                              v-validate="'required'"
                              name="description"
                            />
                            <div class="aab-vie-error-msg">
                              {{ errors.first("description") }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </vs-col>
                  </vs-row>
                </div>
              </div>
              <div class="mt-8">
                <div class="inline-flex">
                  <div>
                    <vs-button
                      class="w-full sm:w-auto"
                      :disabled="!validateFormAddTicket || loadingAddTicket"
                      @click="fetchApplyAddTicket"
                      >Envoyer</vs-button
                    >
                    <vs-button
                      class="w-full sm:w-auto ml-4"
                      type="border"
                      @click="popupAddTicketActive = false"
                      >Annuler</vs-button
                    >
                  </div>
                  <div class="ml-2">
                    <clip-loader
                      :loading="loadingAddTicket"
                      color="primary"
                    ></clip-loader>
                  </div>
                </div>
                <div class="mt-2">
                  <p class="text-danger">* Champ obligatoire</p>
                </div>
              </div>
            </div>
          </vs-popup>
        </div>
      </div>
    </vx-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { en, fr } from "vuejs-datepicker/dist/locale";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import { mixin } from "@/mixins/mixin";
import role from "@/config/role";
import moment from "@/helpers/moment";
import global from "@/config/global";
import Multiselect from "vue-multiselect";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import jsPDF from "jspdf";

export default {
  data() {
    return {
      startDate: "",
      endDate: "",
      ticketStatus: [
        { text: "Nouveau", color: "#F2E205" },
        { text: "Répondu", color: "#378C0F" },
        { text: "Fermé", color: "#D91828" },
      ],
      statusList: [
        { id: "new", label: "Nouveau" },
        { id: "answered", label: "Répondu" },
        { id: "close", label: "Fermé" },
      ],
      objectListField: [
        { id: "Devis", label: "Devis" },
        { id: "Indemnisation", label: "Indemnisation" },
        { id: "Rachat", label: "Rachat" },
        { id: "Remboursement", label: "Remboursement" },
        { id: "Autre", label: "Autre" },
      ],
      projectStatus: "",
      objectChoice: "",
      product: "",
      isResetFilter: false,
      crrItem: {},
      intermediariesTmp: [],
      role: {},
      global: {},
      form: {
        product: "",
        object: "",
        description: "",
      },
      //Pagination
      limit: 1,
      skip: 0,
      currentPage: 1,
      total: 0,
      allPages: 1,
      //Pagination
      loading: false,
      loadingUpdateTicket: false,
      loadingAddTicket: false,
      error: false,
      errorMsg: "",
      crrID: "",
      woIsAction: "",

      //export section
      fileName: "",
      formats: ["xlsx", "csv", "txt"], //formats: ["xlsx", "csv", "txt", "pdf"],
      cellAutoWidth: true,
      selectedFormat: "xlsx",
      headerTitle: ["N°", "Identité", "Réf", "Date", "Produit", "Objet"],
      headerVal: [
        "index",
        "whoCreated",
        "ref",
        "createdAt",
        "product",
        "object",
      ],
      activePrompt: false,
      //end export section
      popupAddTicketActive: false,
      ticketSelected: [],
      crrIDs: [],
    };
  },
  created() {
    // add lang in validation fields
    Validator.localize("fr", VeeValidateFR);
    this.role = role;
    this.global = global;
  },
  mounted() {
    if (
      this.user.type != "super_administrator_sys" &&
      !this.user.person.userAcces.chk_ticket
    ) {
      if (!this.checkUserRole("ticket").all)
        this.$router.push({ name: "Home" });
    }
    this.limit = this.rowsTable[1];
  },
  computed: {
    ...mapGetters("auth", ["user", "userId"]),
    ...mapGetters("ticket", ["allTickets"]),
    ...mapGetters("general", ["rowsTable", "allProduct"]),
    validateFormAddTicket() {
      return (
        !this.errors.any() &&
        this.form.object != "" &&
        this.form.description != "" &&
        this.form.product != ""
      );
    },
  },
  methods: {
    ...mapActions("auth", ["logout"]),
    ...mapActions("ticket", [
      "applyGetTicket",
      "applyGetAllTicket",
      "applyAddTicket",
      "applyPutTicket",
    ]),
    tooltipIconText(data) {
      return data.status === "answered" || data.status === "new"
        ? "UnlockIcon"
        : "LockIcon";
    },
    getProduct(productId) {
      return this.allProduct.find((product) => product.id == productId)
        ? this.allProduct.find((product) => product.id == productId).label
        : "";
    },
    resetFilter() {
      this.isResetFilter = true;
      this.projectStatus = "";
      this.objectChoice = "";
      this.product = "";
      this.startDate = "";
      this.endDate = "";
      this.isResetFilter = false;

      this.fetchApplyGetAllTicket({
        limit: this.limit,
        skip: this.skip,
        startDate: this.startDate ? this.startDate : "",
        endDate: this.endDate ? this.endDate : "",
        status:
          this.projectStatus && this.projectStatus.id
            ? this.projectStatus.id
            : "",
        product: this.product && this.product.id ? this.product.id : "",
        object:
          this.objectChoice && this.objectChoice.id ? this.objectChoice.id : "",
      });
    },
    crrBackgroundColor(stat) {
      let color = { background: "", text: "", color: "" };
      switch (stat) {
        case "new":
          color = { background: "#F2E205", text: "Nouveau ", color: "white" };
          break;
        case "answered":
          color = { background: "#378C0F", text: "Répondu ", color: "white" };
          break;
        case "close":
          color = { background: "#D91828", text: "Fermé", color: "white" };
          break;

        default:
          break;
      }
      return color;
    },
    acceptAlert(data) {
      let newdata = { ...data };
      this.$vs.notify(newdata);
    },
    checkUserRole(pack) {
      return role[this.user.type][pack];
    },
    subString(data) {
      if (data != undefined && data.length > 30) {
        return `${data.substring(0, 30)} ...`;
      } else {
        return data;
      }
    },
    lastLogin(data) {
      return mixin.methods.lastLogin(data.lastLoginAt);
    },
    aboutElement(data) {
      return mixin.methods.aboutElement(data);
    },

    downloadTicket(item) {
      let headerValTxt = ["object", "description", "date"];
      let headerTitleTxt = ["Objet", "Description", "Date"];
      mixin.methods.exportToData(
        [ 
          {
            object: item.object,
            description: item.description,
            date: this.dateElement(item).createdAtInfo,
          },
        ],
        headerTitleTxt,
        headerValTxt,
        `${item.object}_${item.ref}`,
        true,
        "xlsx"
      ); 

      let commentsTab = [];

      item.commentsTicket.forEach((el) => {
        console.log(`${el.user}  ${el.user}`);
        commentsTab.push({
          autor: `${el.user.person.firstname}  ${el.user.person.lastname}`,
          comment: el.content,
        });
      });

      let headerValxls = ["autor", "comment"];
      let headerTitlexls = ["Auteur", "Commentaire"];
      mixin.methods.exportToData(
        commentsTab,
        headerTitlexls,
        headerValxls,
        `${item.object}_${item.ref}`,
        true,
        "xlsx"
      );
    },
    checkViewTicket(item) {
      setTimeout(() => {
        this.$router.push({
          name: "Comment",
          params: { ticketId: item.id },
        });
      }, 100);
    },
    exportToData() {
      if (this.selectedFormat == "pdf") {
        let tableToPrintPdf = [];

        this.allTickets.list.forEach((el, index) => {
          let jsonTmp = {
            index: index + 1,
            whoCreated: el.person.firstname + " " + el.person.lastname,
            ref: el.ref,
            createdAt: this.dateElement(el).createdAtInfo,
            object:
              this.objectChoice && this.objectChoice.id
                ? this.objectChoice.id
                : "",
            product: this.getProduct(el.product),
          };
          tableToPrintPdf.push(jsonTmp);
        });

        let headerArray = [];

        this.headerTitle.forEach((title, index) => {
          headerArray.push({ header: title, dataKey: this.headerVal[index] });
        });
        mixin.methods.exportToPdf(
          [...tableToPrintPdf],
          headerArray,
          this.fileName,
          "LES TICKETS"
        );
      } else {
        let tableToPrint = [];
        this.allTickets.list.forEach((el, index) => {
          let jsonTmp = {
            whoCreated:
              el.whoCreated.person.firstname +
              " " +
              el.whoCreated.person.lastname,
            ref: el.ref,
            infos: this.aboutElement(el).text,
            product: this.getProduct(el.product),
            object: el.object,
            contrat: el.contrat,
            description: el.description,
            status: this.crrBackgroundColor(el.status).text,
            attachFile: this.getFilesLink(el.filesname),
          };
          tableToPrint.push(jsonTmp);
        });

        let headerValTmp = [
          "status",
          "ref",
          "whoCreated",
          "contrat",
          "object",
          "description",
          "attachFile",
          // "product",
          "infos",
        ];

        let headerTitleTmp = [
          "Etat",
          "Réf",
          "Identité client",
          "Contrat",
          "Objet",
          "Message",
          "Fichiers",
          // "Produit",
          "Informations",
        ];

        mixin.methods.exportToData(
          tableToPrint,
          headerTitleTmp,
          headerValTmp,
          this.fileName,
          this.cellAutoWidth,
          this.selectedFormat
        );
      }
      this.clearFields();
    },
    getFilesLink(data) {
      return data
        .map((acc) => `${acc.name}(${this.showFileData(acc.filename)})`)
        .join(", ");
    },
    showFileData(data) {
      if (data == "") {
        return "";
      } else {
        return `${this.$config.serverURL}/downloads/${data}`;
      }
    },
    clearFields() {
      (this.filename = ""),
      (this.fileName = ""),
        (this.cellAutoWidth = true),
        (this.selectedFormat = "xlsx");
    },
    dateElement(data) {
      return mixin.methods.dateElement(data);
    },
    async fetchApplyGetAllTicket(data) {
      this.loading = true;

      try {
        await this.applyGetAllTicket(data);

        this.total = this.allTickets.total;
        let p = Math.ceil(this.total / this.limit);
        this.allPages = p ? p : 1;

        this.loading = false;
        this.error = false;
        this.errorMsg = "";
      } catch (err) {
        this.loading = false;
        this.error = true;
        this.errorMsg = "";

        if (err.response) {
          if (
            err.response.status &&
            err.response.statusText === "Unauthorized"
          ) {
            this.acceptAlert(
              "danger",
              "Authentification",
              "Votre session a expirée"
            );
            await this.logout();
            this.$router.push({ name: "Login" });
          } else {
            err.response.data.errors.forEach((item) => {
              switch (item.code) {
                case "userNotFound":
                  this.errorMsg = "Votre nom d'utilisateur n'existe pas";
                  this.acceptAlert("danger", "Utilisateurs", this.errorMsg);
                  break;

                default:
                  this.acceptAlert(
                    "danger",
                    "Utilisateurs",
                    "Une erreur s'est produite"
                  );
                  break;
              }
            });
          }
        }
      }
    },
    async fetchApplyAddTicket() {
      if (!this.validateFormAddTicket) return false;
      this.loadingAddTicket = true;

      let data = {
        ...this.form,
        product: this.form.product.id,
      };

      try {
        await this.applyAddTicket(data);
        this.loadingAddTicket = false;
        this.acceptAlert("success", "Ajout", "Intermédiaire a été ajouté");
        this.form = {
          product: "",
          object: "",
          description: "",
        };

        this.popupAddTicketActive = false;
      } catch (err) {
        this.loadingAddTicket = false;
        this.error = true;
        this.errorMsg = "";

        if (err.response) {
          if (
            err.response.status &&
            err.response.statusText === "Unauthorized"
          ) {
            this.acceptAlert(
              "danger",
              "Authentification",
              "Votre session a expirée"
            );
            this.popupAddTicketActive = false;
            await this.logout();
            this.$router.push({ name: "Login" });
          } else {
            err.response.data.errors.forEach((item) => {
              switch (item.code) {
                case "userNotFound":
                  this.errorMsg = "Le nom d'utilisateur n'existe pas";
                  this.acceptAlert("danger", "Ajout", this.errorMsg);
                  break;
                case "userNameExistError":
                  this.errorMsg = item.message;
                  this.acceptAlert("danger", "Ajout", this.errorMsg);
                  break;
                case "sendMailError":
                  this.errorMsg = item.message;
                  this.acceptAlert("danger", "Ajout", this.errorMsg);
                  break;

                default:
                  this.acceptAlert(
                    "danger",
                    "Ajout",
                    "Une erreur s'est produite"
                  );
                  break;
              }
            });
          }
        }
      }
    },
    async fetchApplyUpdatedTicket() {
      this.loadingUpdateTicket = true;
      let data = { ticketId: this.crrItem.id, status: "close" };
      try {
        await this.applyPutTicket(data);
        this.loadingUpdateTicket = false;
        this.acceptAlert(mixin.methods.message("ticket", "off").success);
      } catch (err) {
        this.loadingUpdateTicket = false;
        this.error = true;
        this.errorMsg = "";

        if (err.response) {
          if (
            err.response.status &&
            err.response.statusText === "Unauthorized"
          ) {
            this.acceptAlert(
              "danger",
              "Authentification",
              "Votre session a expirée"
            );
            this.popupAddTicketActive = false;
            await this.logout();
            this.$router.push({ name: "Login" });
          } else {
            err.response.data.errors.forEach((item) => {
              switch (item.code) {
                case "userNotFound":
                  this.errorMsg = "Le nom d'utilisateur n'existe pas";
                  this.acceptAlert("danger", "Ajout", this.errorMsg);
                  break;
                case "userNameExistError":
                  this.errorMsg = item.message;
                  this.acceptAlert("danger", "Ajout", this.errorMsg);
                  break;
                case "sendMailError":
                  this.errorMsg = item.message;
                  this.acceptAlert("danger", "Ajout", this.errorMsg);
                  break;

                default:
                  this.acceptAlert(
                    mixin.methods.message("ticket", "off").error
                  );

                  break;
              }
            });
          }
        }
      }
    },
    ChangeStatuts(item) {
      this.crrItem = item;
      let text = `Voulez-vous vraiment fermer le ticket portant référence ${item.ref} ?`;

      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirmation`,
        text,
        acceptText: "Confirmer",
        cancelText: "Annuler",
        accept: this.fetchApplyUpdatedTicket,
      });
    },
    ticketSelectedDouble(item) {
      if (this.checkUserRole("ticket").show) {
        this.checkViewTicket(item);
      } else {
        this.acceptAlert(
          "danger",
          this.role.notAllowTitle,
          this.role.notAllowText
        );
      }
    },
  },
  watch: {
    startDate() {
      if (!this.isResetFilter) {
        this.fetchApplyGetAllTicket({
          limit: this.limit,
          skip: this.skip,
          startDate: this.startDate ? this.startDate : "",
          endDate: this.endDate ? this.endDate : "",
          status:
            this.projectStatus && this.projectStatus.id
              ? this.projectStatus.id
              : "",
          product: this.product && this.product.id ? this.product.id : "",
          object:
            this.objectChoice && this.objectChoice.id
              ? this.objectChoice.id
              : "",
        });
      }
    },
    endDate() {
      if (!this.isResetFilter) {
        this.fetchApplyGetAllTicket({
          limit: this.limit,
          skip: this.skip,
          startDate: this.startDate ? this.startDate : "",
          endDate: this.endDate ? this.endDate : "",
          status:
            this.projectStatus && this.projectStatus.id
              ? this.projectStatus.id
              : "",
          product: this.product && this.product.id ? this.product.id : "",
          object:
            this.objectChoice && this.objectChoice.id
              ? this.objectChoice.id
              : "",
        });
      }
    },
    projectStatus() {
      if (!this.isResetFilter) {
        this.fetchApplyGetAllTicket({
          limit: this.limit,
          skip: this.skip,
          startDate: this.startDate ? this.startDate : "",
          endDate: this.endDate ? this.endDate : "",
          status:
            this.projectStatus && this.projectStatus.id
              ? this.projectStatus.id
              : "",
          product: this.product && this.product.id ? this.product.id : "",
          object:
            this.objectChoice && this.objectChoice.id
              ? this.objectChoice.id
              : "",
        });
      }
    },
    objectChoice() {
      if (!this.isResetFilter) {
        this.fetchApplyGetAllTicket({
          limit: this.limit,
          skip: this.skip,
          startDate: this.startDate ? this.startDate : "",
          endDate: this.endDate ? this.endDate : "",
          status:
            this.projectStatus && this.projectStatus.id
              ? this.projectStatus.id
              : "",
          product: this.product && this.product.id ? this.product.id : "",
          object:
            this.objectChoice && this.objectChoice.id
              ? this.objectChoice.id
              : "",
        });
      }
    },
    currentPage() {
      this.skip = (this.currentPage - 1) * this.limit;

      this.fetchApplyGetAllTicket({
        limit: this.limit,
        skip: this.skip,
        startDate: this.startDate ? this.startDate : "",
        endDate: this.endDate ? this.endDate : "",
        status:
          this.projectStatus && this.projectStatus.id
            ? this.projectStatus.id
            : "",
        product: this.product && this.product.id ? this.product.id : "",
        object:
          this.objectChoice && this.objectChoice.id ? this.objectChoice.id : "",
      });
    },
    limit() {
      this.skip = 0;

      this.fetchApplyGetAllTicket({
        limit: this.limit,
        skip: this.skip,
        startDate: this.startDate ? this.startDate : "",
        endDate: this.endDate ? this.endDate : "",
        status:
          this.projectStatus && this.projectStatus.id
            ? this.projectStatus.id
            : "",
        product: this.product && this.product.id ? this.product.id : "",
        object:
          this.objectChoice && this.objectChoice.id ? this.objectChoice.id : "",
      });
    },
  },
  components: {
    ClipLoader,
    Multiselect,
    quillEditor,
  },
};
</script>

<style lang="scss">
.aab-vie-icon-clickable {
  display: flex;
  justify-content: space-around;
  cursor: pointer;
}
</style>
